import promise from 'es6-promise';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
promise.polyfill();

import Vue from 'vue';
import App from './App.vue';
import router from './router'; //路由
import ElementUI from 'element-ui'; //el
import 'element-ui/lib/theme-chalk/index.css'; //el样式表
import store from '@/store'; //vuex
// import '@/assets/css/eduBase.css'; //基础样式表
import eventBus from '@/utils/eventBus'; //消息组件
Vue.prototype.$eventBus = eventBus;
import windowSizeMixin from '@/mixins/windowSizeMixin'; //混入-浏览器窗口监听
// import * as echarts from 'echarts'; // 在import的后面，echarts的前面加一个 * as
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
// Vue.prototype.$echarts = echarts;

if (process.env.NODE_ENV === 'production') {
	if (window) {
		window.console.log = function () {};
	}
}
Vue.mixin(windowSizeMixin);
Vue.use(ElementUI);
// 检测是否是 IE 浏览器
const isIE =
	/msie|trident/i.test(navigator.userAgent) &&
	!/opera/i.test(navigator.userAgent);
if (isIE) {
} else {
	// 如果不是 IE 浏览器，正常加载应用
	new Vue({
		render: (h) => h(App),
		router,
		store,
	}).$mount('#app');
}
